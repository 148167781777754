import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'appletonFactor',
    'importationCost',
    'currencyPrice',
    'quantity',
    'price',
    'firstAccesory',
    'secondAccesory',
    'discount',
    'margin',
    'certificates',
    'totalPrice',
    'unitPriceWithDiscount',
    'stock',
    'clientDiscount',
    'discountedToClient'
  ]

  removeItemFieldValues(){
    this.quantityTarget.value = 0
    this.quantityTarget.dispatchEvent(new Event('change'))
  }

  calculatePrice(){
    let totalPriceElement = this.totalPriceTarget
    let unitPriceWithDiscountElement = this.unitPriceWithDiscountTarget
    let quantity = Number(this.quantityTarget.value)
    let price = Number(this.priceTarget.value)
    let firstAccesory = Number(this.firstAccesoryTarget.value)
    let secondAccesory = Number(this.secondAccesoryTarget.value)
    let appletonFactor = Number(this.appletonFactorTarget.value)
    let importationCost = Number(this.importationCostTarget.value)
    let currencyPrice = Number(this.currencyPriceTarget.value)
    let discount = Number(this.discountTarget.value)
    let clientDiscount = Number(this.clientDiscountTarget.value)
    let margin = Number(this.marginTarget.value)
    let certificates = Number(this.certificatesTarget.value)

    try {
      this.discountedToClientTarget.value = (price * quantity) * (clientDiscount / 100)
      if (clientDiscount > 0) {
        price = price - (price * clientDiscount / 100)
      }

      if (discount > 0) {
        price = price - (price * discount / 100)
      }

      if(appletonFactor > 0){
        price = price * appletonFactor
      }

      if(importationCost > 0){
        price = price * (1 + (importationCost / 100))
      }

      if(currencyPrice > 0){
        price = price * currencyPrice
      }

      if(margin > 0){
        price = price / (1 - (margin / 100))
      }

      let unitPriceWithDiscount = (price + certificates + firstAccesory + secondAccesory).toFixed(unitPriceWithDiscountElement.getAttribute('toFixed'))
      let total = (unitPriceWithDiscount * quantity).toFixed(totalPriceElement.getAttribute('toFixed'))

      unitPriceWithDiscountElement.value = unitPriceWithDiscount
      totalPriceElement.value = total

    }
    catch(err) {
      console.log(err)
      totalPriceElement.value = 0
    }

    totalPriceElement.dispatchEvent(new Event('change'))
    this.validateStock()
  }

  validateStock() {
    let texts = this.element.querySelectorAll('.stock-warning')

    for(let text of texts) {
      text.remove()
    }
    let stock = Number(this.stockTarget.value)

    if (stock < Number(this.quantityTarget.value)) {
      this.quantityTarget.insertAdjacentHTML('afterend', '<small class="form-text text-warning stock-warning">Posible falta de stock</small>')
    }
  }
}
