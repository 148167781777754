import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['input', 'destination']

  connect(){
    // Add custom mutation observer to hidden field
    let context = this
    let observer = new MutationObserver(function(mutations, observer){
      context.get_entity_info(context.inputTarget.value)
    })
    const config = { characterData: true, attributes: true }
    observer.observe(this.inputTarget, config)

    context.get_entity_info(context.inputTarget.value)
  }

  get_entity_info(entity_id) {
    if(entity_id == ''){
      this.destinationTarget.innerHTML = '<small>Aún no seleccionas un elemento...</small>'
      return false
    }

    let url = this.data.get('entity-url').replace(/DUMMY/g, entity_id)
    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.destinationTarget.innerHTML = html
      })
  }
}
