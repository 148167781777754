import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['variant', 'description', 'price', 'stock']

  connect(){
    let context = this
    let observer = new MutationObserver(function(mutations, observer){
      context.get_item_info(context.variantTarget.value)
    })
    const config = { characterData: true, attributes: true }
    observer.observe(this.variantTarget, config)
  }

  get_item_info(variant_id){
    if(variant_id == ''){
      return false
    }

    let url = '/variants/' + variant_id + '.json'

    fetch(url)
      .then(response => {
        if(!response.ok){
          console.log(response.status)
          return false
        }
        return response.json()
      })
      .then(variant => {
        this.update_item_info(variant)
      })
  }

  update_item_info(variant){
    this.descriptionTarget.value = variant.description
    this.priceTarget.value = Number(variant.price).toFixed(this.priceTarget.getAttribute('toFixed'))
    this.stockTarget.value = variant.stock_available

    this.priceTarget.dispatchEvent(new Event('change'))
  }
}
