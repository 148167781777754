import { Autocomplete } from 'stimulus-autocomplete'

export default class extends Autocomplete {
  connect() {
    super.connect()
    this.addFilterId()

    this.element.addEventListener('autocomplete.change', () => {
      this.addFilterId()
    })
  }

  addFilterId() {
      const controllers = document.getElementsByClassName('id-autocomplete')
      let values = []

      for(let controller of controllers) {
        let value = controller.getElementsByClassName('id-autocomplete-hidden')[0].value
        if (controller.offsetParent == null || value == '') {
          continue
        }

        values.push(value)
      }


      for(let controller of controllers) {
        let url = new URL(controller.dataset.url)

        values.forEach(value => {
          url.searchParams.append('used[]', value)
        })

        controller.dataset.autocompleteUrl = url.toString()
      }
  }
}
