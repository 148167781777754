import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "chart" ]

  connect() {
    this.chartTarget.textContent = 'Cargando'
    this.load_chart()
  }

  reloadData(event) {
    const chartId = event.target.dataset.chart
    const chart = Chartkick.charts[chartId]
    const dateSelected = this.getDateSelected(chartId)

    chart.updateData(`${this.data.get('url')}?month=${dateSelected.month}&year=${dateSelected.year}`)
  }

  getDateSelected(chartId) {
    const monthSelect = document.getElementById(`month-${chartId}`)
    const yearSelect = document.getElementById(`year-${chartId}`)

    return {
      month: monthSelect ? monthSelect.value : '',
      year: yearSelect.value
    }
  }

  load_chart(){
    const chart_type = this.data.get('type')
    const chart_function = this[chart_type + '_chart']
    const target = this.data.get('target')
    const dateSelected = this.getDateSelected(target)

    if( typeof(chart_function) == 'function' ){
      chart_function(target, `${this.data.get('url')}?month=${dateSelected.month}&year=${dateSelected.year}`)
    }
  }

  pie_chart(target, url){
    new Chartkick.PieChart(
      target,
      url,
      {
        messages: {
          empty: 'Sin información disponible'
        },
        decimal: ',',
        thousands: '.'
      }
    )
  }

  column_chart(target, url) {
    new Chartkick.ColumnChart(
      target,
      url,
      {
        messages: {
          empty: 'Sin información disponible'
        },
        decimal: ',',
        thousands: '.'
      }
    )
  }

  bar_chart(target, url) {
    new Chartkick.BarChart(
      target,
      url,
      {
        messages: {
          empty: 'Sin información disponible'
        },
        decimal: ',',
        thousands: '.'
      }
    )
  }

  line_chart(target, url) {
    new Chartkick.LineChart(
      target,
      url,
      {
        messages: {
          empty: 'Sin información disponible'
        },
        decimal: ',',
        thousands: '.'
      }
    )
  }
}
