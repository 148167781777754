import { Controller } from "stimulus"

export default class extends Controller {
  // TODO: Add targets to prices and emit change event for them
  // this way, all inputs should recalculate their data
  // TODO: Add binding between currency input and data-currency on
  // item field controller, then read config from that and apply
  // toFixed for each element.
  static targets = [
    'customerElement',
    'customerDiscount',
    'discountInput',
    'itemClientDiscount',
    'currency',
    'formatByCurrency'
  ]

  connect(){
    let context = this
    const config = { childList: true, attributes: true, subtree: true}
    let customerContainerObserver = new MutationObserver((_mutations, _observer) => {
      context.updateDiscount()
    })

    customerContainerObserver.observe(this.customerElementTarget, config)
    this.propagateCurrency()
  }

  updateDiscount(){
    if(!this.hasCustomerDiscountTarget){
      return false
    }

    if(this.discountInputTarget.value == ""){
      this.discountInputTarget.value = this.customerDiscountTarget.innerText
      this.discountInputTarget.dispatchEvent(new Event('change'))
    }
  }

  get currentCurrency() {
    return this.currencyTarget.value
  }

  get currentCurrencyConfig(){
    const config = {
      'USD': { toFixed: 2, step: 0.001 },
      'CLP': { toFixed: 0, step: 1 }
    }

    return config[this.currentCurrency]
  }

  propagateCurrency(){
    let context = this
    this.formatByCurrencyTargets.forEach( (target) => {
      target.setAttribute('step', context.currentCurrencyConfig['step'])
      target.setAttribute('toFixed', context.currentCurrencyConfig['toFixed'])
      target.value = Number(target.value).toFixed(context.currentCurrencyConfig['toFixed'])
    })
  }
}
