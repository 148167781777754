import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['add_item', 'template']

  add_association(e) {
    e.preventDefault()
    const content = this.templateTarget.innerHTML.replace(/TEMPLATE/g, new Date().valueOf())

    this.insert_association(content, e.target.dataset.position, e)
  }

  insert_association(content, position = 'afterbegin', e = null) {
    const valid_positions = [
      'afterbegin',
      'beforeend',
      'downto',
      'upto'
    ];

    if(['afterbegin', 'beforeend'].includes(position)){
      this.add_itemTarget.insertAdjacentHTML(position, content);
    } else if(position === 'upto'){
      const item = e.target.closest(".nested-fields");
      item.insertAdjacentHTML('beforebegin', content);
    } else {
      const item = e.target.closest(".nested-fields");
      item.insertAdjacentHTML('afterend', content);
    }
  }

  clone_association(e) {
    e.preventDefault()
    const elementCloning = e.target.closest('.nested-fields')
    const content = elementCloning.cloneNode(true)
    this.add_itemTarget.insertBefore(content, elementCloning)
    let innerContent = content.innerHTML
    innerContent = innerContent.replace(/_\d_/g, `_${new Date().valueOf()}_`)
    innerContent = innerContent.replace(/\[\d\]/g, `[${new Date().valueOf()}]`)
    content.innerHTML = innerContent
    content.querySelectorAll("input[name*='id'").forEach((input) => input.value = '')
    content.querySelector(".title").textContent = 'Nueva variante'
  }

  remove_association(e) {
    e.preventDefault()

    let item = e.target.closest(".nested-fields")
    // if _destroy attribute exists means this is a database record, so
    // send it to server. Else just make the input disabled to avoid
    // submission of the fields.
    if(item.querySelector("input[name*='_destroy']") != null) {
      item.querySelector("input[name*='_destroy']").value = 1
    } else {
      item.querySelectorAll("input").forEach((input) => input.disabled = true )
    }

    item.style.display = 'none'

    let autocomplete_element = item.getElementsByClassName('id-autocomplete')[0]
    if(autocomplete_element != null){
      autocomplete_element.dispatchEvent(new CustomEvent('autocomplete.change'))
    }
  }
}
