import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'includeOnCalculation',
    'itemToCalculate',
    'discountToCalculate',
  ]

  updateItem(){
    this.itemToCalculateTarget.dataset.includeOnCalculation = this.includeOnCalculationTarget.checked;
    this.discountToCalculateTarget.dataset.includeOnCalculation = this.includeOnCalculationTarget.checked;
    this.itemToCalculateTarget.dispatchEvent(new Event('change'));
  }
}
