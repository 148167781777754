import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'itemPrice',
    'itemPriceWithDiscount',
    'itemClientDiscount',
    'price',
    'discountEnabled',
    'discountPercentage',
    'discount',
    'extraDiscountEnabled',
    'extraDiscountPercentage',
    'extraDiscount',
    'totalWithTax',
    'totalWithDiscount',
    'itemDiscountedToClient'
  ]

  recalculateDiscount() {
    this.itemClientDiscountTargets.forEach(itemClientDiscount => {
      let value = ""
      if (this.discountEnabledTarget.checked) {
        value = this.discountPercentageTarget.value
      }
      itemClientDiscount.value = value
      itemClientDiscount.dispatchEvent(new Event('change'))
    })

    this.calculatePrices()
  }

  // Only print enabled targets
  priceTargets(){
    return this.itemPriceTargets.filter((e) => {
      return e.dataset.includeOnCalculation == "true";
    });
  }

  discountedToClientTargets(){
    return this.itemDiscountedToClientTargets.filter((e) => {
      return e.dataset.includeOnCalculation == "true";
    });
  }

  calculatePrices(e){
    try{
      let price = Number(this.priceTargets().reduce((acc, i) => Number(i.value) + acc, 0));
      let discount = Number(this.discountedToClientTargets().reduce((acc, i) => Number(i.value) + acc, 0));
      let total = price;
      let extraDiscount = total * Number(this.extraDiscountPercentageTarget.value) / 100;

      if(this.extraDiscountEnabledTarget.checked == true){
        total = total - extraDiscount
      }

      this.totalWithDiscountTarget.value = total.toFixed(this.totalWithDiscountTarget.getAttribute('toFixed'))

      let totalWithTax =  total * 1.19

      this.priceTarget.value = price.toFixed(this.priceTarget.getAttribute('toFixed'))
      this.discountTarget.value = discount.toFixed(this.discountTarget.getAttribute('toFixed'))
      this.extraDiscountTarget.value = extraDiscount.toFixed(this.extraDiscountTarget.getAttribute('toFixed'))
      this.totalWithTaxTarget.value = totalWithTax.toFixed(this.totalWithTaxTarget.getAttribute('toFixed'))
    }
    catch(err) {
      this.priceTarget.value = 0
      this.discountTarget.value = 0
      this.totalWithTaxTarget.value = 0
      console.log(err)
    }
  }
}
