import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['element']

  sync_content(e) {
    let content = e.currentTarget.value
    this.elementTarget.innerHTML = content
  }
}
